
#sr{
    margin-left: 2rem;
    margin-right: 2rem;
    /* margin-bottom: 2rem; */
}

#srenata{
    text-align: center;
}

#srimg{
    width: 8rem;
}
.vermas button:hover{
    color:#FFFFFF !important;
}
#rsubtitulo{
    text-align: center;
    color:#494949 ;
    font-weight: bold;
}

.srtitulop{
    font-weight: bold;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.srul{
    margin-left: 0.8rem;
    border-bottom: none;
}


.srcardtitulo{
    width: 26.4rem;
    text-align: center;
    color: black;
}

.srcardtitulo:hover{
    background: #3268C0;
    cursor: pointer;
    color: white;
}

.srcardtitulo:focus{
    background: #3268C0;
    cursor: pointer;
    color: white;
}

.srtitulos{
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;      
}

.bcparrafo3{
    text-align: justify;
    padding-right: 0.8rem;

}

.bcparrafo3 > ul{
    list-style: none;
    padding-left: 5px;
    margin-top: 15px;
}
.bcparrafo3 > ul > li{
    position: relative;
    padding-left: 3px;
    margin-bottom: 10px;
}

.bcparrafo3 > ul > li:before{
    content: "-";
    position: absolute;
    left: -10px;
}

.srform{
    margin-top: 3rem;
}

.srlabelpersonas{
    color: grey;
    padding-right:1rem ;
    font-size: 12px;
}

.srnpersonas{
    margin-left: 0rem;
}

.srlabel{
    color: grey;
    padding-right:1rem ;
    white-space: pre-wrap;
}

.srselect{
    margin-left: 0rem;
}

.srlabelselect{
    color: grey;
    white-space: pre-wrap;
    margin-left: 0rem;

}
  .srnedad{
      margin-left: 0rem;
  }



    .srlabelvalormensual{
        margin-left: 3.5rem;
    }
  .srnvalormensual{
      margin-left: 0rem;
  }

.dropdown.hk--custom--select.primary{
    border-style: groove;
}

.col-lg-9{
    margin-left: -2rem;
}


input[type=number]::-webkit-inner-spin-button {
    opacity: 1
}

.dropdown-item.active{
        background-color:#3268C0;
}

.dropdown-item.selected{
    background-color:#3268C0;
}

.dropdown-item:hover{
    background-color:#3268C0;
}


button.btn.dropdown-toggle.btn-primary.bs-placeholder{
    background: #3268C0;
    border-color: #3268C0;
}

button.btn.dropdown-toggle.btn-primary{
    background: #3268C0;
    border-color: #3268C0;
    color:white;
}

.srbtncotiza{
    color: white;
    background: #E98922;
    border-color: #E98922;
    /*margin-left: 1rem;*/
    /*width: 12rem;*/
}

.srbtncotiza:hover{
    color: white;
    font-weight: bold;
    background: #3268C0;
    border-color: #3268C0;
}

.btn-outline-primary:hover{
    color: white;
}

button#input-spinner-left-button{
    background: #3268C0;
    border-color: #3268C0;
    color: white;
}

button#input-spinner-right-button{
    background: #3268C0;
    border-color: #3268C0;
    color: white;
}

.form-check-input{
    background-color: #3268C0;
    border-color: white;
    margin-right: 0.5rem;
}

.form-check-input:hover{
    background-color: #3268C0;
    border-color: white;
}

.form-check-input:focus{
    background-color: #3268C0;
    border-color: #3268C0;
}

.form-check-input:checked{
    background-color: #3268C0;
    border-color: #3268C0;
}
.hk--custom--select{
    width: 100% !important;
}
.hk--custom--select .dropdown-menu{
    background-color: #3268C0;
}

button:focus:not(:focus-visible){
    background-color: #3268C0; 
}

.dropdown-item:hover, .dropdown-item:focus{
    color: white;
}

.dropdown-item{
    color: white;
}


.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn{
    background-color: #3268C0;
    border-color: white;
    color: white;
}

.btn-group > .btn:nth-child(n + 3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn{
    background-color: #3268C0;
    border-color: white;    
    color: white;
}


/* added */
#sr table td{
    vertical-align: middle;
}

/* Estilos a pantallas pequeñas menores de 1400px*/

@media (max-width: 1400px) {

  .srbtncotiza{
      /* margin-left: -2rem; */
      /*width: 9rem;*/
  }

  .srselect{
      /* margin-left: -2rem; */
  }

  .srlabelselect{
      color: grey;
      white-space: pre-wrap;
      /*margin-left: -2rem;*/
  }

  .srcardtitulo{
    width: 21.5rem;
  }

}


/* Estilos a pantallas pequeñas menores de 1200px*/

@media (max-width: 1200px) {

  .srbtncotiza{
      /* margin-left: 0.5rem; */
      /* width: 9rem; */
  }

    .srlabelvalormensual{
        margin-left: 2rem;
    }
    .srnvalormensual{
        margin-left: -2rem;
    }

}



/* Estilos a pantallas pequeñas menores de 1024px*/

@media (max-width: 1024px) {

  .srform{
      margin-top: 3rem;
  }

  .srlabelpersonas{
      color: grey;
      padding-right:1rem ;
      font-size: 12px;
  }

  .srnpersonas{
      /*margin-left: 1rem;*/
  }

  .srnedad{
      /* margin-left: -1rem; */
  }

  .srlabel{
      color: grey;
      padding-right:1rem ;
      white-space: pre-wrap;
  }

  .srselect{
      /* margin-left: -1.8rem; */
      margin-left: 0;
  }


    .srbtncotiza{
        /* margin-left: 4.5rem; */
        /* width: 9rem; */
    }



    .srlabelvalormensual{
        margin-left: -1rem;
    }
    .srnvalormensual{
        margin-left: -1rem;
    }



}


/* Estilos a pantallas pequeñas menores de 992px*/
/* 990 */
@media (max-width: 992px) {

      .srselect{
          /* margin-left: -1.8rem; */
      }


        .srbtncotiza{
            /* margin-left: 4.5rem; */
            /* width: 8rem; */
        }

        .srnpersonas{
            /* margin-left: -1rem; */
        }
        .srnedad{
              /* margin-left: -1rem; */
        }

        .srnvalormensual{
            margin-left: -1rem;
            /*width: 750px;*/

        }
        .srlabelvalormensual{
            margin-left: -1.8rem;
        }

}



/* Estilos a pantallas pequeñas menores de 900px*/

@media (max-width: 900px) {

  .srlabelselect{
      color: grey;
      white-space: pre-wrap;
      /*margin-left: 0.5rem;*/
  }

    .srselect{
        /* margin-left: 0.5rem; */
    }


      .srbtncotiza{
          /* margin-left: 5.5rem; */
          /* width: 9rem; */
      }


              .srnvalormensual{
                  margin-left: -1rem;
              }


}

/* Estilos a pantallas pequeñas menores de 768px*/

@media (max-width: 768px) {

                .srnvalormensual{
                    margin-left: 0;
                }

}

  /* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {
.srbtncotiza{
    /* margin-left: 5rem; */
}

.srform{
    margin-left: 0rem;
    margin-top: 0rem;
}

.srselect{
    margin-left: 0;
}

.srlabelselect{
    margin-left: 0;
}

.srnpersonas{
    margin-left: 0;
}

.srnedad{
      margin-left: 0rem;
}

.srlabelvalormensual{
    margin-left: -0.7rem;
}

.srnvalormensual{
    margin-left: 0;
}

}

@media (max-width: 360px) {
    .srcardtitulo{
        width: 19rem;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) { /*** LG ***/
    .srnpersonas .form-label,
    .srnedad .form-label{
        white-space: pre-line !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) { /*** MD ***/
    .srnpersonas .form-label,
    .srnedad .form-label{
        white-space: pre-line !important;
    }

    .srnpersonas .form-label + button{
        padding-left: 0;
    }
    

    .srbtncotiza{
        width: 8rem;
    }
}
