#inicio{
    width: inherit;
    text-align: center;    
}

.inav{
    max-height: 180px;
}

#ilogo{
    width: 12rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 4rem;
}

.navbar-brand {
    margin-right: 1rem;
}

.navbar-light .navbar-nav .nav-link{
    color:#000000;
    font-size: 0.8rem;
    margin-left: 1rem;
    margin-top: 1.2rem;
}

.navbar-light .navbar-nav .nav-link{
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-decoration: none;
    color: #333;
}

.navbar-light .navbar-nav .nav-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: #3268C0;
    transition: left .8s;
}

.navbar-light .navbar-nav .nav-link:hover:after {
    left: 0;
}


#barraenlace{
    background:#ffffff;
    height: 100px;
}

.navbar{
    background:#ffffff;
    transition: all 0.6s;
}

#parrafo{
    justify-content: right;
    height: 45vh;
    overflow: hidden;
}

#parrafo > div{ align-self: center !important; }


/* Estilo para capsula amarilla */
  .inline-block {
      display: inline-block;
  }

  .bg-brand-yellow {
      --tw-bg-opacity: 1;
      background-color: rgba(255,201,19,var(--tw-bg-opacity));
  }
.capsula{
    border-radius: 75px;
    font-weight: 700;
    line-height: 1;
    border-radius: 3.25rem;
    /*white-space: nowrap;*/
    padding: 0.20em 0.30em
  }

  .bg-brand-grey {
      --tw-bg-opacity: 1;
      background-color: rgba(224,233,242,var(--tw-bg-opacity));
  }
  .capsulaTarjeta{
      border-radius: 75px;
      font-weight: 200;
      line-height: 0;
      border-radius: 3.25rem;
      border: 2.5px solid white;
      background: #ffbfbb ;
      padding: 1em 0.35em;
    }

    @media (min-width: 390.02px) and (max-width: 500px){
        .navbar-brand{ margin-right: 0;}
    }
    @media (min-width: 500.02px) and (max-width: 830px){
        .navbar-brand{ margin-right: 0;} 
    }
    @media (min-width: 830.02px) and (max-width: 991.98px){        
        .navbar-brand{ margin-right: 0;}
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { /*** LG ***/        
        .navbar-light .navbar-nav .nav-link{ font-size: 0.6rem; }
        
    }
    @media (min-width: 1200px) and (max-width: 1299.99px) { /*** XLG ***/

    }