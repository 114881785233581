#jn{
    margin-left: 2rem;
    margin-right: 2rem;
}

.jntitulos{
    font-weight: bold;
    margin-top: 2rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
}

.jncard{

    border-width: 2px;
    transition: 0.5s;
}

#jnimg1{
    height: 5rem;
    margin-top: 2rem;
}

#jnimg2{
    height: 5rem;
    margin-top: 2rem;
}

#jnimg3{
    height: 5rem;
    margin-top: 2rem;

}

#jnimg4{
    height: 5rem;
    margin-top: 2rem;
}

#cflogo{
    width: 4rem;
}

.shadow-ms{
    box-shadow: 7px 7px 5px #A0A0A0BF;
}

@media (min-width: 1200px) and (max-width: 1399.98px){ /*** XL ***/
    .jntitulos{
        font-size: 0.9rem;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px){ /*** LG ***/
    .jntitulos{
        font-size: 0.75rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

@media (min-width: 768px) and (max-width: 991.98px){ /*** MD ***/
    .jntitulos{
        font-size: 0.8rem;        
    }
}

@media (min-width: 576px) and (max-width: 767.98px){ /*** SM ***/
    .jntitulos{
        font-size: 0.8rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

@media (min-width: 450px) and (max-width: 575.98px){ /*** Custom XS ***/
    .jntitulos{
        font-size: 0.9rem;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    }
}

@media (max-width: 991.98px) { 
    #jn section > .row > .col{ 
        margin-bottom: 20px; 
    }
}

@media (max-width: 419.98px) { 
    .jntitulos{
        font-size: 0.8rem;
        margin-left: 1.8rem;
        margin-right: 1.8rem;        
    }
}

