body {
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;  
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  }

/* boton secciones: inicio, proceso contracion, testimonios  */
.morefaq
{
  text-align: right;
  width: 100%;
}
.boton{
    color: white;
    background: #c8c8c8;
    border-color:#c8c8c8;
    margin: 2rem 0;
    font-weight: bold;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  
  }
  
  .boton:hover{
    border-bottom: 3px solid #c8c8c8;
    border-left: white;
    border-right: white;
    border-top: 3px solid #c8c8c8;
    background-color: white;
    color: #c8c8c8;
  }

  /* fondo Accordion, seccion todos nos preguntan  */

.tnp{
  background-color: #E9EDF5;
}

  .tnpaccordion{
    text-align: center;
  }

.accordion-button{
  background-color: #E9EDF5;
}

.accordion-button:not(.collapsed) {
  background-color: #E9EDF5;
}

.accordion-body {
  background-color: #E9EDF5;
  text-align: center;
}

.tnpnombre{
  color: black;
  font-weight: bold;
}

  .accordion-button::after{
    /* background-image: url(./imagenes/Testimonios/mas.png); */    
    content: "+";
    background-image: none;
    color: #3268C0;
    font-size: 28px;
    line-height: 12px;
  }

  .accordion-button:hover::after{ color: #E98922; }
  
  .accordion-button:not(.collapsed)::after{
    /* background-image: url(./imagenes/Testimonios/menos.png); */
    /* transform: rotate(0); */
    content: "-";
    background-image: none;
    transform: rotate(0);
  }

  #FAQX1::after{
    content: "";
    display: none;
  }

  /* .tnpacc:focus{
    background-color: blue;
  } */

  .tnpacc:focus:not(:focus-visible){
    background-color: #E9EDF5; 
}

/* Added: Todos nos preguntan */
#tnp .accordion-body{ text-align: justify; }

#cs{
  margin-top: 25px;
}
#bc,
#cs{
  margin-bottom: 25px;
}

#sr:before,
#cf:before,
#bc:before,
#tnp:before   {
    display: block;
    content: " ";
    margin-top: -50px;
    height: 50px;
    visibility: hidden;
}

    /* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {

    .fondoFaqs{
      font-size: 11px; 
    }
  
    .tnpnombre{
      font-size: 11px;
    }

    .accordion-body {
      font-size: 11px;
    }
  }