@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(./fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(./fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(./fonts/Poppins-Bold.ttf);
}

.titulos{
  text-align: center;
  /*font-weight: bold;*/
  font-family: Poppins-SemiBold;
  margin-left: 2rem;
  margin-right: 2rem;
  color: #3268C0;
}

.subtitulos{
  text-align: center;
  color: #9A9A9A;
}

.btn:focus{
  box-shadow:none !important;
}


.Uf{
  list-style: none;
}




