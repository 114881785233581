
#bcsection1{
    margin-left: 5rem;
}
/* Added */
#bcsection1 h4{ line-height: 1.5; text-align: justify; }

#bccard{
    background: #3268C0;
    color:#ffffff;
}

.bcparrafos{
    background-color: rgba(255,255,255, 0.85);;
    border-radius: 10px;
    border: 1px solid white;
    width: 50%;
    height: 130px;
    display: flex;
    margin-right: 1rem;
    color: #3268C0;
    padding-left: 15px;
    padding-right: 15px;
}
.bcparrafos-small{ 
    width: 20%;
}

.cfparrafo2{
    text-align: right;
}

.bctexto{
    margin: auto;
    text-align: center;
} 

.bccontainer1{
    display: flex;
    display: -webkit-flex;
    text-align: center;
    justify-content: center; 
}

.bccontainer2{
    display: flex;
    display: -webkit-flex;
    text-align: center;
    justify-content: center;
}

.shadow-bc {
    box-shadow: 7px 7px 5px #A0A0A0BF;
}

@media (min-width: 1200px) and (max-width: 1399.98px){ /*** XL ***/
    .bcparrafos{
        padding-left: 5px;
        padding-right: 5px;
    }
    .bctexto h5{
        font-size: 0.9rem;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px){ /*** LG ***/
    .bcparrafos{
        padding-left: 5px;
        padding-right: 5px;
        width: 52%;
    }
    .bcparrafos-small{
        width: 23%;
    }
    .bctexto h5{
        font-size: 0.9rem;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) { /*** MD ***/
    .bctexto h5{
        font-size: 0.8rem;
    }
}

@media (max-width: 767.98px) {

    .bccontainer1{
        flex-direction: column;
    }
    .bcparrafos{
        width: 100%;
        margin-bottom: 1rem;
    }
    .bctexto h5{
        font-size: 0.8rem;
    }
}

/* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {
    #bcsection1 h4{font-size: 0.6rem;}
    #bccard{
        font-size: 0.6rem;
    }

    .bctexto h4{font-size: 0.6rem;}
}

    
/* Estilos a pantallas pequeñas menores de 414px*/

@media (max-width: 415px) {


    #bcsection1 h4{margin-top: 1rem;font-size: 0.625rem;}
    #bccard{
    margin-top: 1rem;
    font-size: 0.625rem;
    }

    .bctexto h4{font-size: 0.6rem;}
    
}


        /* Estilos a pantallas pequeñas menores de 412px*/

@media (max-width: 412px) {
    #bcsection1 h4{margin-top: 1rem;font-size: 0.625rem;}
    #bccard{
    margin-top: 1rem;
    font-size: 0.625rem;
    }

    .bctexto h4{font-size: 0.6rem;}

}

        /* Estilos a pantallas pequeñas menores de 380px*/ 

@media (max-width: 380px) {
    #bcsection1 h4{font-size: 0.55rem;}
    #bccard{
        font-size: 0.55rem;
    }

    .bctexto h4{font-size: 0.6rem;}
}

        /* Estilos a pantallas pequeñas menores de 361px*/

@media (max-width: 361px) {
    #bcsection1 h4{font-size: 0.45rem;}
    #bccard{
        font-size: 0.4rem;
    }

    .bctexto h4{font-size: 0.6rem;}
 
}

        /* Estilos a pantallas pequeñas menores de 321px*/

@media (max-width: 321px) {
    #bcsection1 h4{font-size: 0.32rem;line-height: 0.8rem;}     
    #bccard{
        font-size: 0.32rem;
        line-height: 0.8rem;
    }

    .bctexto h4{font-size: 0.6rem;}

}
