.nav-link.active
{
    background: #3268C0 !important;
    color: white !important;    
}
.cfdivprin{
    margin-left: 2rem;
    margin-right: 2rem;
}

.container2{
    margin-top: 0rem;
}


.cftitulos{
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;  
    
}

.cfparrafo{
    text-align: right;
    font-size: 0.7rem;
    margin-top: -1rem;
}
 .cfcard{
	position: relative;
    overflow: hidden;
} 
.cfcard::after{
    content: "";
    width: 100px;
    height: 200px;
	background: #E98922;
	position: absolute;
	z-index: 1;
	top: -100px;
	left: -50px;
    transform: rotate(45deg);
}

.cfnumeracion{
    text-align: left;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-top: 1rem;
    z-index: 2;
}

.cfcardtitulo{
    /* width: 19.7rem; */
    text-align: center;
    color: black;
}

.cfcardtitulo:hover{
    background: #3268C0;
    cursor: pointer;
    color: white;
}

.cfcardtitulo:focus{
    background: #3268C0;
    cursor: pointer;
    color: white;
}


#cfimg1{
    margin-top: 2rem;
    /* width: 7rem; */
    height: 5rem;    
}

#cfimg1:hover{
    transform:scale(1.2);
}

#cfimg2{
    margin-top: 2rem;
    /* width: 7rem; */
    height: 5rem;   
}

#cfimg2:hover{
    transform:scale(1.2);
}

#cfimg3{
    margin-top: 2rem;
    /* width: 7.3rem; */
    height: 5rem;
}

#cfimg3:hover{
    transform:scale(1.2);
}

#cfimg4{
    margin-top: 2rem;
    /* width: 7rem; */
    height: 5rem;
}

#cfimg4:hover{
    transform:scale(1.2);
}

.cfinfo{
font-size: 1.5rem;
}

.cfpinfo{
text-align: right;
margin-top: -2rem;
margin-right: 1rem;
}

#nav-cf{
    border-bottom: none !important;
}
#nav-cf > li{
    width: 25%;
}


@media (min-width: 1200px) and (max-width: 1399.98px){ /*** XL ***/

}

@media (min-width: 992px) and (max-width: 1199.98px){ /*** LG ***/

}

@media (min-width: 768px) and (max-width: 991.98px){ /*** MD ***/
    .cftitulos{
        font-size: 0.9rem;
    }
}

@media (min-width: 576px) and (max-width: 767.98px){ /*** SM ***/
    #nav-cf > li{
        width: 50%;
    }
    .cftitulos{
        font-size: 0.9rem;
    }
}

@media (max-width: 575.98px) { 
    #nav-cf > li{
        width: 50%;
    }
    .cftitulos{
        font-size: 0.8rem;
    }
}

@media (max-width: 419.98px) { 
    .cfcardtitulo{
        padding-left: 0;
        padding-right: 0;
    }
}




/* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {
    #cfcol{
        font-size: 11px;
    }

    .cfcolum{
        margin-bottom: 1rem;
    }

    .cficono{
    height: 1.5rem;
    }
/* 
    .cftitulos{
    font-size: 11px;
    } */

    .cfparrafo{
        font-size: 7px;
        margin-top: -1.5rem;
    }

    .cfcardtitulo{
        /* width: 8rem; */
        text-align: center;
    }

    .cfcard::after{
        content: "";
        width: 100px;
        height: 200px;
        background: #E98922;
        position: absolute;
        z-index: 1;
        top: -100px;
        left: -50px;
    }
    
    .cfnumeracion{
        text-align: left;
        color: white;
        font-weight: bold;
        font-size: 0.8rem;
        margin-left: 0.4rem;
        margin-top: 0.4rem;
        z-index: 2;
    
    }
    
}

        /* Estilos a pantallas pequeñas menores de 415px*/    

  @media (max-width: 415px) {
    
    #cfdiv3{
        margin-left: 0.1rem;
    }

    .cficono{
        height: 2rem;
      }

  }

        /* Estilos a pantallas pequeñas menores de 316px*/  

  @media (max-width: 361px) {

    .cfcardtitulo{
        /* width: 6rem; */
        text-align: center;
    }

    /* .cftitulos{
        font-size: 0.6rem;
    } */
  }

        /* Estilos a pantallas pequeñas menores de 321px*/

  @media (max-width: 321px) {
    

    .cficono{
        height: 1.5rem;
      }

      
     .cfcardtitulo{
        /* width: 6rem; */
        text-align: center;
    }

    /* .cftitulos{
        font-size: 0.6rem;
    } */
  }


  
